/*
  Modal styles
*/

.ReactModal__Body--open {
  /* Empêche le scroll quand un modal est ouvert */
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/*
  Google autocomplete styles
*/

.autocomplete-dropdown-container {
  background-color: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  position: absolute;
  z-index: 1;
}

.suggestion-item,
.suggestion-item--active {
  cursor: pointer;
  padding: 12px;
}

.location-search-input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: initial !important;
}

.smallTable {
  font-size: 13px;
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: white;
}

.noData {
  font-size: 13px;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: white;
}

.smallTable td {
  padding: 5px;
  border-top: 1px solid #444;
}

.smallTable tr:first-child td {
  font-weight: bold;
  border: none;
}

.tiptap {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1em;
  margin-top: 1em;
  width: 80%;
}

.tiptap * {
  margin-bottom: 0px;
  margin-top: 4px;
}

.tiptap a {
  cursor: pointer;
}

.tiptap a:hover {
  text-decoration: underline;
  opacity: 0.7;
}